<template>
  <b-card title="页面初始化">
    <el-form
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      label-width="200px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item label="1、是否初始化隐私组织架构">
            <el-radio-group v-model="form.isInitPrivateOrg">
              <el-radio
                v-for="(item,index) in yesOrNoOptions"
                :key="index"
                :label="item.value"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="2、是否初始化制度流程文件">
            <el-radio-group v-model="form.isInitRegime">
              <el-radio
                v-for="(item,index) in yesOrNoOptions"
                :key="index"
                :label="item.value"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="3、系统管理员密码"
            prop="AdminPwd"
          >
            <el-input
              v-model="form.AdminPwd"
              placeholder="请输入密码"
              :type="pwdType ? 'password' : 'text'"
            >
              <i
                slot="suffix"
                class="el-icon-view cursor-pointer"
                @click="pwdType=!pwdType"
              />
            </el-input>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="4、确认密码"
            prop="confirmAdminPwd"
          >
            <el-input
              v-model="form.confirmAdminPwd"
              placeholder="请再次输入密码"
              :type="pwdTyp1 ? 'password' : 'text'"
            >
              <i
                slot="suffix"
                class="el-icon-view cursor-pointer"
                @click="pwdTyp1=!pwdTyp1"
              />
            </el-input>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="5、是否初始化角色用户">
            <el-radio-group v-model="form.isInitRoleResource">
              <el-radio
                v-for="(item,index) in yesOrNoOptions"
                :key="index"
                :label="item.value"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </b-col>
        <!--        <b-col md="12">-->
        <!--          <el-tabs-->
        <!--            v-model="activeName"-->
        <!--            type="border-card"-->
        <!--          >-->
        <!--            <el-tab-pane-->
        <!--              v-for="(item,index) in roleMenus"-->
        <!--              :key="index"-->
        <!--              :label="item.roleName"-->
        <!--              :lazy="true"-->
        <!--              :name="String(index+1)"-->
        <!--            >-->
        <!--              <el-tree-->
        <!--                ref="elTree"-->
        <!--                accordion-->
        <!--                :data="item.resources"-->
        <!--                node-key="id"-->
        <!--                show-checkbox-->
        <!--                style="margin-top:5px"-->
        <!--                :default-expand-all="false"-->
        <!--                :default-checked-keys="checkedIds"-->
        <!--                :highlight-current="true"-->
        <!--                :props="defaultProps"-->
        <!--              >-->
        <!--                <span-->
        <!--                  slot-scope="{ node, data }"-->
        <!--                  class="custom-tree-node"-->
        <!--                >-->
        <!--                  <span>{{ node.label }}</span>-->
        <!--                  <span /></span>-->

        <!--              </el-tree>-->
        <!--            </el-tab-pane>-->
        <!--          </el-tabs></b-col>-->
      </b-row>
      <div
        class="text-center"
      >
        <el-button
          type="primary"
          @click="submitForm"
        >
          启 动
        </el-button>
      </div></el-form>
  </b-card>
</template>
<script>
import { GetInitFirstDefaultRole, InitFirstData } from '@/api/system/system'
import { error, success } from '@/@core/utils/utils'

export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.AdminPwd !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.AdminPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        AdminPwd: [
          { validator: validatePass, trigger: 'change' },
        ],
        confirmAdminPwd: [
          { validator: validatePass2, trigger: 'change' },
        ],
      },
      pwdType: true,
      pwdTyp1: true,
      activeName: '1',
      loading: false,
      yesOrNoOptions: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      roleMenus: [],
      resourceList: [],
      checkedIds: [],
      defaultProps: {
        children: 'childResource',
        label: 'name',
      },
      form: {
        AdminPwd: '',
        confirmAdminPwd: '',
        isInitPrivateOrg: true,
        isInitRegime: true,
        isInitRoleResource: true,
      },
    }
  },
  created() {
    this.getInitFirstDefaultRole()
  },
  methods: {
    getInitFirstDefaultRole() {
      GetInitFirstDefaultRole().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.roleMenus = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          InitFirstData(this.form)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.$router.push({ path: '/login' })
              } else {
                error(resData.msg)
              }
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">

</style>
